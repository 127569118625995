/**
 * @name data.names
 * @namespace First names and last names.
 */
define('data/namesCanada',[], function () {
    "use strict";

    var  first, last;

    // http://www.census.gov/genealogy/www/data/1990surnames/names_files.html
    // Name, Cumulative Frequency
    first = [
		
			
		["Alex"],
		["Simon"],
		["Samuel"],
		["Nicolas"],
		["Nathan"],
		["William"],
		["David"],
		["Ben"],
		["Charles"],
		["Gabriel"],
		["Antoine"],
		["Jordan"],
		["Vincent"],
		["Eric"],
		["Kevin"],
		["Adam"],
		["Brandon"],
		["Ryan"],
		["Matthew"],
		["Etienne"],
		["Alexandre"],
		["Ethan"],
		["Michael"],
		["Anthony"],
		["Justin"],
		["John"],
		["Josh"],
		["Philippe"],
		["Kyle"],
		["Maxime"],
		["Nick"],
		["James"],
		["Mathieu"],
		["Nicholas"],
		["Daniel"],
		["Owen"],
		["Max"],
		["Liam"],
		["Sam"],
		["Austin"],
		["Mark"],
		["Andrew"],
		["Dave"],
		["Cédric"],
		["Aidan"],
		["Luke"],
		["Matt"],
		["Tony"],
		["Jason"],
		["Olivier"],
		["Jack"],
		["Chris"],
		["Thomas"],
		["Guillaume"],
		["Tyler"],
		["Jeremy"],
		["Christian"],
		["Julien"],
		["Francis"],
		["Paul"],
		["Raphael"],
		["Colton"],
		["Jake"],
		["Alexis"],
		["Antonio"],
		["Frederic"],
		["Bill"],
		["Brad"],
		["Cameron"],
		["Will"],
		["Joshua"],
		["George"],
		["Evan"],
		["Alexander"],
		["Jacob"],
		["Christophe"],
		["Xavier"],
		["Zach"],
		["Richard"],
		["Riley"],
		["Jean"],
		["Jean-Sébastien"],
		["Sonny"],
		["Joey"],
		["Logan"],
		["Zachary"],
		["Ty"],
		["Walid"],
		["Felix"],
		["Jesse"],
		["Emile"],
		["Isaac"],
		["Jimmy"],
		["Ken"],
		["Jules"],
		["Pascal"],
		["Léonard"],
		["Joseph"],
		["Bryce"],
		["Isaak"]
	

    ];
	

	
    // http://www.census.gov/genealogy/www/data/1990surnames/names_files.html
    // Name, Cumulative Frequency
    last = [
	
		["Abbott"],
		["Adams"],
		["Alexander"],
		["Allen"],
		["Anderson"],
		["Andrews"],
		["Armstrong"],
		["Arnold"],
		["Atkins"],
		["Atkinson"],
		["Austin"],
		["Bailey"],
		["Baker"],
		["Ball"],
		["Banks"],
		["Barker"],
		["Barnes"],
		["Barrett"],
		["Barry"],
		["Bartlett"],
		["Bates"],
		["Baxter"],
		["Bell"],
		["Bennett"],
		["Berry"],
		["Bird"],
		["Bishop"],
		["Black"],
		["Booth"],
		["Boyd"],
		["Bradley"],
		["Brady"],
		["Brooks"],
		["Brown"],
		["Bryant"],
		["Burke"],
		["Burns"],
		["Burton"],
		["Butler"],
		["Byrne"],
		["Cameron"],
		["Campbell"],
		["Carpenter"],
		["Carr"],
		["Carroll"],
		["Carter"],
		["Chambers"],
		["Chapman"],
		["Clark"],
		["Cohen"],
		["Cole"],
		["Coleman"],
		["Collins"],
		["Connolly"],
		["Cook"],
		["Cooper"],
		["Cox"],
		["Craig"],
		["Crawford"],
		["Cross"],
		["Cunningham"],
		["Curtis"],
		["Daly"],
		["Daniels"],
		["Davidson"],
		["Davis"],
		["Dawson"],
		["Day"],
		["Dean"],
		["Dixon"],
		["Dodd"],
		["Doherty"],
		["Douglas"],
		["Doyle"],
		["Duncan"],
		["Dunn"],
		["Edwards"],
		["Elliott"],
		["Ellis"],
		["Evans"],
		["Farrell"],
		["Ferguson"],
		["Field"],
		["Fisher"],
		["Fitzgerald"],
		["Fleming"],
		["Fletcher"],
		["Flynn"],
		["Ford"],
		["Foster"],
		["Fowler"],
		["Fox"],
		["Francis"],
		["Fraser"],
		["Freeman"],
		["Fuller"],
		["Gallagher"],
		["Gardner"],
		["Garrett"],
		["George"],
		["Gibson"],
		["Gilbert"],
		["Gill"],
		["Goodwin"],
		["Gordon"],
		["Graham"],
		["Grant"],
		["Gray"],
		["Green"],
		["Gregory"],
		["Griffin"],
		["Griffiths"],
		["Hall"],
		["Hamilton"],
		["Hammond"],
		["Hanson"],
		["Hardy"],
		["Harper"],
		["Harris"],
		["Harrison"],
		["Hart"],
		["Harvey"],
		["Hawkins"],
		["Hayes"],
		["Henderson"],
		["Henry"],
		["Hicks"],
		["Higgins"],
		["Hill"],
		["Hoffman"],
		["Holland"],
		["Holmes"],
		["Hopkins"],
		["Howard"],
		["Howell"],
		["Hudson"],
		["Hughes"],
		["Hunt"],
		["Hunter"],
		["Jackson"],
		["Jacobs"],
		["James"],
		["Jenkins"],
		["Jennings"],
		["Johnson"],
		["Jones"],
		["Jordan"],
		["Kelly"],
		["Kennedy"],
		["Kenny"],
		["Kerr"],
		["King"],
		["Knight"],
		["Lambert"],
		["Lane"],
		["Lang"],
		["Lawrence"],
		["Lawson"],
		["Lee"],
		["Levy"],
		["Lewis"],
		["Little"],
		["Lloyd"],
		["Long"],
		["Lowe"],
		["Lucas"],
		["Lynch"],
		["Lyons"],
		["Mackay"],
		["Maguire"],
		["Marsh"],
		["Marshall"],
		["Martin"],
		["Mason"],
		["Matthews"],
		["May"],
		["McCarthy"],
		["McCormick"],
		["McDonald"],
		["McGee"],
		["McGrath"],
		["McKenzie"],
		["Miller"],
		["Mills"],
		["Mitchell"],
		["Moore"],
		["Moran"],
		["Morgan"],
		["Morris"],
		["Morrison"],
		["Moss"],
		["Munro"],
		["Murphy"],
		["Murray"],
		["Myers"],
		["Nelson"],
		["Newman"],
		["Newton"],
		["Nichols"],
		["Nicholson"],
		["Nolan"],
		["Norman"],
		["Norris"],
		["O'Brien"],
		["O'Connor"],
		["Oliver"],
		["Olson"],
		["O'Neill"],
		["O'Reilly"],
		["Osborne"],
		["O'Sullivan"],
		["Owen"],
		["Page"],
		["Palmer"],
		["Parker"],
		["Parsons"],
		["Patterson"],
		["Payne"],
		["Pearce"],
		["Pearson"],
		["Perkins"],
		["Perry"],
		["Peters"],
		["Peterson"],
		["Phillips"],
		["Porter"],
		["Potter"],
		["Powell"],
		["Price"],
		["Quinn"],
		["Ray"],
		["Reed"],
		["Rees"],
		["Reynolds"],
		["Rhodes"],
		["Rice"],
		["Richards"],
		["Richardson"],
		["Riley"],
		["Robbins"],
		["Roberts"],
		["Robertson"],
		["Robinson"],
		["Rogers"],
		["Rose"],
		["Ross"],
		["Rowe"],
		["Russell"],
		["Ryan"],
		["Sanders"],
		["Scott"],
		["Sharp"],
		["Shaw"],
		["Shepherd"],
		["Simmons"],
		["Simpson"],
		["Smith"],
		["Snyder"],
		["Spencer"],
		["Stanley"],
		["Stevens"],
		["Stevenson"],
		["Stewart"],
		["Stone"],
		["Sutton"],
		["Tate"],
		["Taylor"],
		["Thomas"],
		["Thompson"],
		["Todd"],
		["Tucker"],
		["Turner"],
		["Wagner"],
		["Walker"],
		["Wallace"],
		["Walsh"],
		["Walters"],
		["Walton"],
		["Ward"],
		["Warren"],
		["Watkins"],
		["Watson"],
		["Watts"],
		["Weaver"],
		["Webb"],
		["Webster"],
		["Wells"],
		["West"],
		["Wheeler"],
		["White"],
		["Whittaker"],
		["Wilkinson"],
		["Williams"],
		["Williamson"],
		["Willis"],
		["Wilson"],
		["Wolfe"],
		["Wood"],
		["Wright"],
		["Young"]


	
      ];

    return {
        first: first,
        last: last
    };
});
